/* You can add global styles to this file, and also import other style files */

html,
body {
  max-width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  font-family: "Inter";
  font-style: normal;
  box-sizing: border-box !important;
}

.reset-img {
  width: 52px;
  height: auto;
  margin-top: -4px;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

/* * {

  font-family: "Inter", sans-serif !important;
} */

.cursor {
  cursor: pointer;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.main {
  overflow: hidden !important;
}

*:focus,
button.focus,
button:focus,
.btn.focus,
.btn:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn:hover {
  color: none !important;
}

*,
*:focus,
*:hover {
  box-shadow: none;
  outline: none;
}

.eligablePanelClass {
  margin-top: 30px !important;
  margin-left: -30px !important;
}

.prayerPanelClass {
  margin-top: 30px !important;
  margin-left: -76px !important;
}

.donationPanelClass {
  margin-top: 30px !important;
  margin-left: -106px !important;
}

/*

====================================================================
                      Login-css-start
====================================================================
*/

.login-background-image {
  max-width: 100%;
  background-image: url("~src/assets/images/login/login-bg.png");
  background-size: cover;
  position: relative;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-position: center;
}

.login-card-main-section .card {
  width: 415px;
  /* height: 545px; */
  background: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 16px;
}

.login_header_img {
  width: 85px;
}

.login-heading h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 104%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2b3244;
  margin: 4px 0 6px 0;
}

.login-heading p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 104%;
  text-align: center;
  color: #75809c;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-form-field {
  width: 100%;
}

.login-btn-continue .btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  background: #3787ff;
  border-radius: 28px;
  width: 100%;
  padding: 10px;
}

.forgot_passoword a {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 142%;
  text-align: center;
  color: #2b3244;
  cursor: pointer;
  text-decoration: none;
}

.forgot_header_img {
  width: 100px;
}

.password_header_img {
  width: 145px;
}

.forgot-heading h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #1a264e;
}

.forgot-heading p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #75809c;
}

.forgot-card-main-section .card {
  width: 420px;
  /* height: 482px; */
  background: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 16px;
}

.forgot-card-body {
  padding: 14px 12px;
}

.forgot-card-main-section .otp-card {
  width: 440px !important;
  /* height: 523px !important; */
}

.ngx-otp-input {
  width: 50px !important;
  height: 40px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #e1e7f7 !important;
  text-align: center !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-size: 32px;
}

.ngx-otp-input:invalid {
  border-bottom: 1px solid #ff0000 !important;
}

.otp-input {
  width: 60px !important;
  height: 50px;
  border-radius: 0px !important;
  border-bottom: 1px solid #e1e7f7 !important;
  text-align: center;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-size: 32px;
}

/* .otp-input:focus {
  outline-style: solid;
  outline-color: red !important;
  border-bottom: 1px solid #ff0000 !important;
} */

/* .otp-input:valid {
  border-bottom: 1px solid #ff0000 !important;
} */

.resend_btn h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #75809c;
}

.resend_btn h5 button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #1091ff !important;
  border: none;
  background-color: #ffffff;
}

.counter {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #2b3244;
}

.counter_img {
  margin: -2px 0 0 0;
}

.forgot-card-main-section .password-card {
  width: 482px !important;
  /* height: 540px !important; */
}

.success p {
  /* width: 350px; */
  text-align: center;
  margin: 0 auto;
}

.forgot-card-main-section .success-card {
  width: 430px;
  /* height: 446px; */
}

.Back-to-login .btn {
  width: 84%;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  display: flex;
}

/*

====================================================================
                      Login-css-END
====================================================================
*/

/*

====================================================================
                      Sidenavbar-css-Start
====================================================================
*/

mat-sidenav {
  width: 250px;
  min-height: 100vh !important;
}

.sidemenu-wrap {
  position: relative;
  height: 100%;
  background: #491a40;
}

.menu-wrap {
  background: #491a40;
}

.mat-drawer .mat-drawer-inner-container::-webkit-scrollbar {
  width: 5px;
}

.mat-drawer .mat-drawer-inner-container::-webkit-scrollbar-track {
  background: #ffc021;
}

.mat-drawer .mat-drawer-inner-container::-webkit-scrollbar-thumb {
  background: #fd6a65;
}

.mat-drawer .mat-drawer-inner-container::-webkit-scrollbar-thumb:hover {
  background: #fd6a65;
}

.sidebar-logo {
  text-align: center;
  width: 235px;
  padding: 25px 10px;
}

.mat-drawer .mat-drawer-inner-container {
  overflow: inherit !important;
  height: 100vh;
}

.menu-wrap ul li a img {
  filter: grayscale(1);
  opacity: 0.7;
  margin-right: 20px;
}

.menu-wrap {
  padding: 15px 0 67px 0;
  position: relative;
}

.menu-wrap p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0 20px 10px;
}

.menu-wrap ul {
  padding: 10px 0px;
  text-decoration: none;
  list-style: none;
}

.menu-wrap ul li a {
  padding: 17px 64px 17px 17px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  text-decoration: none;
}

.mat-drawer-content {
  /* margin-left: 243px !important; */
}

.menu-wrap ul + h5 {
  margin-top: 20px;
}

.active-link {
  color: #ffffff !important;
}

.menu-wrap ul li a.active-page {
  position: relative;
  color: #ffffff;
  background: rgba(253, 106, 101, 0.2);
}

.menu-wrap ul li a.active-page img {
  opacity: 1;
  filter: none;
  color: #ffffff !important;
}

.menu-wrap ul li a.active-page:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 41px;
  margin: 8px 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fd6a65;
  border-radius: 20px 0px 0px 20px;
}

/*

====================================================================
                      Sidenavbar-css-END
====================================================================
*/

/*

====================================================================
                      User-list-css-END
====================================================================
*/

.user-list-heading h6 {
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #2b3244;
}

.user-list {
  width: 35px;
  height: 45px;
}

.user-list-heading {
  /* width: 42%; */
  width: 47%;
}

.search-bar input {
  background: #ffffff;
}

.search-bar {
  width: 23%;
}

.search-icon {
  position: absolute;
  top: 25%;
  right: 31%;
  margin-top: -10px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white;
  border: none;
}

.calendra-field {
  width: 15%;
}

.user-list-drop-down select {
  background: #ffffff;
  border: 0.5px solid #e1e7f7;
  border-radius: 32px;
}

.user-list-drop-down {
  width: 11%;
}

.user-list-drop-down select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("./assets/images/panel_images/drop-down-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 88%;
  background-position-y: 15px;
}

.user-list-drop-down select option {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  /* Grays / 100 */
  color: #2b3244;
}

.graph {
  border-left: 1px solid #e1e7f7;
  /* transform: rotate(90deg); */
  height: 43px;
}

.graph-img {
  margin-top: -4px;
}

.create-btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  background: #2b3244;
  border-radius: 31px;
  color: #ffffff;
  padding: 10px 14px;
}

.user-header-back h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #2b3244;
  margin: 0 0 5px 0;
}

.user-header-back p span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #75809c;
  margin: 0;
}

.user-header-back p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2b3244;
}

.details_user h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  color: #27272e;
}

.details_user p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #3787ff;
  padding: 7px;
  text-align: center;
  background: #e7f4ff;
  border: 0.3px solid #0074d6;
  border-radius: 26px;
}

.user-header-details-img-section {
  background: #ffffff;
  border-radius: 12px;
}

.edit-user .btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  background: #3787ff;
  border-radius: 31px;
  padding: 10px 14px;
}

/* card-styles */

.card-details-users h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #75809c;
  margin: 0;
}

.card-details-users h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #2b3244;
}

.users-count {
  border: 0.5px solid #edf1fa;
  border-radius: 12px;
  width: 100%;
}

.users-count .card-body {
  padding: 7px 18px;
}

.total-user-bg {
  background: #e7f4ff;
  border: 0.5px solid #66b8ff;
}

.total-existing-bg {
  background: #ebfff5;
  border: 0.5px solid #83ffc3;
}

.total-active-bg {
  background: #fff3e5;
  border: 0.5px solid #ffb65c;
  border-radius: 12px;
}

.total-non-bg {
  background: #ffecec;
  border: 0.5px solid #ff6c6c;
}

.eligable {
  color: #0dbc67 !important;
  background: #ebfff5;
  border-radius: 15px;
  text-align: center;
  padding: 5px 0;
}

.eligable1 {
  color: #0dbc67 !important;
  background: #ebfff5;
  border-radius: 15px;
  text-align: center;
  padding: 5px 0;
  width: 50%;
}

.sent {
  color: #1291ff !important;
  background: #e6f4ff;
  border-radius: 15px;
  text-align: center;
  padding: 5px 0;
  width: 100%;
}

.pending {
  color: #ff8d00 !important;
  background: #fff3e4;
  border-radius: 15px;
  text-align: center;
  padding: 5px 0;
  width: 100%;
}

.rejected {
  color: #ff4342 !important;
  background: #ffeceb;
  border-radius: 15px;
  text-align: center;
  padding: 5px 0;
  /* width: 50%; */
}

.not-eligable {
  color: #ffa12e !important;
  background: #fff3e5;
  border-radius: 15px;
  text-align: center;
  padding: 5px 10px;
}

.not-member {
  color: #ff4242 !important;
  background: #ffecec;
  border-radius: 15px;
  text-align: center;
  padding: 5px 10px;
}

/* card-styles */

.hr-line-user {
  width: 94%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 49px;
  padding: 9px -56px 0 -42px;
  border: 0;
  border-top: 0.5px solid #e1e7f7;
}

.details-heading {
  width: 12%;
}

.details-heading h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #75809c;
}

.details-heading-user h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #2b3244;
}

.prayer-request-section p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #768da9;
}

.completed h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  margin: -9px 0 0 0;
}

.completedtype {
  color: #0dbc67;
  background: #ebfff5;
  border-radius: 15px;
  padding: 9px 14px;
}

.rejected {
  color: #ff4342;
  background: #ffeceb;
  border-radius: 15px;
  padding: 5px 14px;
}

.request p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  width: 100%;
  color: #1a264e;
  word-break: break-word;
}

.service-req-details h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1a264e;
  margin: 0 0 5px 0;
}

.service-req-details p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #75809c;
}

.bank-details h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #2b3244;
  margin: 0 0 6px 0;
}

.bank-details h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #75809c;
}

.amount p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #3787ff;
}

.count p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16.069px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  padding: 6px;
  gap: 10px;
  width: 30px;
  height: 30px;
  background: #3787ff;
  border: 0.3875px solid #91ccff;
  border-radius: 6.2px;
  margin: 17px 11px 0 0px;
}

.details-count p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #75809c;
  margin: 0 0 6px 0;
}

.details-committments h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1a264e;
  margin: 0 0 5px 0;
}

.details-committments h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #5a657f;
}

.commitiment-obeying p,
.commitiment-obeying h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #75809c;
}

.commitiment-obeying p span,
.commitiment-obeying h4 span {
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #0dbc67;
}

.commitiment-obeying h4 span {
  color: #ee1b22;
}

.commitiment-obeying p span {
  color: #0dbc67;
}

.commitiment-obeying p {
  margin-left: -35px;
  margin-bottom: 0;
}

.c1-img {
  height: 15px;
  width: 15px;
  margin: 0 2px 0 0;
}

/*

====================================================================
                      User-list-css-END
====================================================================
*/

/*

====================================================================
                      deactivate-dialog-css-START
====================================================================
*/

.Deactive-user h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #2b3244;
}

.Deactive-user {
  display: grid;
  place-items: center;
}

.Deactive-user p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #75809c;
  width: 89%;
}

.Cancel-btn .btn-cancel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  background: #ff4242;
  border-radius: 31px;
  gap: 10px;
  padding: 14px 14px;
  color: #ffffff;
  width: 45%;
  margin: 0 9px;
}

.Cancel-btn .btn-Deactivate {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  color: #2b3244;
  text-align: center;
  padding: 14px 14px;
  width: 45%;
  margin: 0 9px;
  background: #ffffff;
  border: 0.5px solid #d5ddf3;
  border-radius: 33px;
}

/*

====================================================================
                      deactive-dialog-css-END
====================================================================
*/

/*

====================================================================
                      service-request-css-END
====================================================================
*/

.calendra-img {
  vertical-align: middle !important;
  margin-bottom: 10px !important;
}

.img-cake {
  display: block;
}

.request-img {
  position: relative;
  display: inline-block;
}

.birthday_cake_header {
  position: absolute;
  top: 89%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.birthday_cake_header h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #ffffff;
}

.export-button button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  padding: 10px 15px;
  background: #2b3244;
  border-radius: 31px;
}

.myPanelDonationrole {
  margin-top: 30px !important;
  margin-left: -7px !important;
}

.myPrayerPanelClass {
  margin-top: 30px !important;
  margin-left: -62px !important;
}

/*

====================================================================
                      service-request-css-END
====================================================================
*/

.myPanelClass {
  margin-top: 30px !important;
  margin-left: -30px !important;
}

.myPanelDonationcause {
  margin-top: 30px !important;
  margin-left: -77px !important;
}

/*

====================================================================
                      Analystics-css-start
====================================================================
*/

.present-dot {
  width: 8px;
  height: 8px;
  background: #0dbc67;
  border-radius: 50%;
  display: inline-block;
  margin: 4px 11px 0 0px;
}

.romania-dot {
  width: 8px;
  height: 8px;
  background: #ff4242;
  border-radius: 50%;
  display: inline-block;
  margin: 4px 11px 0 0px;
}

.charts-bg {
  background: #ffffff;
  /* Shadow/shadow-sm */
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
}

.p-f-dot {
  background: #491a40 !important;
}

.a-s-dot {
  background: #ad6000 !important;
}

.m-p-dot {
  background: #fdc332 !important;
}

.c-dot {
  background: #5a657f !important;
}

.b-c-dot {
  background: #3787ff !important;
}

/*

====================================================================
                      Analystics-css-start
====================================================================
*/

/*

====================================================================
                      Spiritual-css-start
====================================================================
*/

.heading-fitness h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #2b3244;
}

.all-section-heading h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #2b3244;
  margin: 8px 0 0 0;
}

.all-section-members h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  /* color: #ffffff; */
  padding: 10px 18px;
  width: 153px;
  background: #e7f4ff;
  /* border: 0.5px solid #66b8ff; */
  color: #64aeef;
  border-radius: 31px;
  width: max-content;
}

.progress-bar-success h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #5a657f;
  text-align: center;
}

.progress-bar-title h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #2b3244;
}

.progress-bar-title p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  width: max-content;
  color: #5a657f;
}

/*

====================================================================
                      Spiritual-css-start
====================================================================
*/

/*

====================================================================
                      CMS-css-start
====================================================================
*/

.push-header h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #27272e;
  margin: 0px 4px 5px 0;
}

.push-header p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #75809c;
  margin: 0;
}

.header-tv h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #2b3244;
  margin-left: 40px;
}

.header-tv h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #75809c;
}

.btn-upload {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #1091ff;
  padding: 10px 20px;
  /* height: 38px;
  width: 214px; */
  background: #e7f4ff;
  border: 0.5px solid #66b8ff;
  border-radius: 33px;
  cursor: pointer;
}

.source-heading p,
.push-heading p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #75809c;
  margin: 16px 0 0 0;
  width: max-content;
}

.details-push-nofications p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #2b3244;
  margin: 0;
}

.created-details-section {
  width: 27% !important;
}

.created-details-section p {
  margin: 4px 0 0 0 !important;
}

.push-heading {
  width: 29%;
}

.push-notofications-col {
  margin-left: 63px;
}

/* cms-dialog */

.cms-heading-succes-section h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #1a264e;
  margin: 10px 0 0 0;
}

.cms-heading-succes-section p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #75809c;
  width: 380px;
  margin: 10px 0 0 0;
}

.okay-btn button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  padding: 10px 14px;
  width: 91%;
  background: #3787ff;
  border-radius: 28px;
  border: none;
}

/* cms-dialog */

/*

====================================================================
                      CMS-css-start
====================================================================
*/

/*

====================================================================
                      pagenotfound-css-start
====================================================================
*/

.pagenotfound-Details h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 158%;
  text-align: center;
  color: #2b3244;
  margin: 0;
}

.pagenotfound-Details p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.2px;
  color: #75809c;
  width: 450px;
  margin: 0;
}

.pagenotfound-Details p a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  color: #1091ff;
}

/*

====================================================================
                      pagenotfound-css-start
====================================================================
*/

.invalid-feedback {
  color: #e91e63;
  font-size: 13px;
  font-weight: 500;
  font-family: "Inter";
  line-height: 20px;
  text-align: left;
}

.spinner-loader {
  position: absolute;
  top: 50;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #777575;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
